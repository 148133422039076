<template>
  <!-- dataTable -->
  <div class="tw-container">
    <div class="management-datatable">
      <DataTable
        class="p-datatable-sm"
        :scrollHeight="`${scrollHeight}px`"
        :value="billList"
        dataKey="id"
        :loading="dataTabelLoading"
        :rowHover="true"
        :paginator="true"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rows="D4Row"
        :rowsPerPageOptions="[20, 50, 100]"
        :scrollable="true"
        currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
        v-model:filters="searchGlobal"
        filterDisplay="menue"
        stateStorage="local"
        stateKey="dt-state-bill-local"
        sortField="id"
        :sortOrder="-1"
        v-model:selection="selectItems"
        v-model:expandedRows="expandedRows"
        stripedRows
        :rowClass="rowClass"
      >
        <template #header>
          <div class="d-flex align-items-center mb-2">
            <FieldFilter
              whitchOneTable="bill"
              :D4FieldFilter="fieldFilter"
            ></FieldFilter>
            <div class="w-100">
              <p>表格搜尋</p>
              <input
                class="form-control me-2"
                v-model="searchGlobal['global'].value"
                placeholder="表格搜尋: 顧客姓名，帳單單號，寄出時間"
                style="max-width: 99%"
              />
            </div>
          </div>
          <!-- <div>
            <button class="tw-btn tw-btn-success me-2 mb-2" @click="expandAll">
              ＋展開全部</button
            ><button class="tw-btn tw-btn-success mb-2" @click="collapseAll">
              －收合全部
            </button>
          </div> -->
        </template>
        <Column v-if="fieldFilter[0].selected" :expander="true" style="max-width: 50px" />
        <!-- 選取 -->
        <Column
          v-if="fieldFilter[0].selected"
          selectionMode="multiple"
          style="max-width: 50px"
        ></Column>
        <!-- 頭像 -->
        <Column v-if="fieldFilter[1].selected" field="" header="圖示" style="min-width: 90px; max-width: 90px">
          <template #body="{ data }">
            <div class="d-flex align-items-start">
              <img
                v-if="!loadingForBodies"
                class="shot-icon me-1"
                src="@/assets/icon/camera.png"
                alt="截圖"
                name="shotCamera"
                title="截圖"
                @click="shot(data)"
              />
              <Image
                class="table-img"
                :url="data.profilePicture"
                v-if="data.profilePicture"
                alt="大頭貼"
                style="width: 50px; height: 50px"
              ></Image>
              <img
                src="@/assets/other-images/nobody.png"
                alt="沒頭貼"
                v-else
                style="width: 50px; height: 50px"
              />
            </div>
          </template>
        </Column>
        <!-- 顧客 -->
        <Column
          v-if="fieldFilter[2].selected"
          field="buyerName"
          header="顧客"
          style="min-width: 150px; max-width: 150px"
          class="fw-bolder"
          sortable
        >
          <template #body="{ data }">
            <p>ID: {{ data.buyerId }}</p>
            <p>
              姓名: 
              <router-link
                v-if="currentPage === 'Bill'"
                :to="`/seller/store/${storeId}/participant/${data.buyerId}/merchOrder`"
                class="text-primary"
                target="_blank"
                >{{ data.buyerName }}</router-link
              >
              <span v-else>{{ data.buyerName }}</span>
            </p>
            <p>暱稱: {{ data.buyer.nickName }}</p>
            <p>賣場: {{ data.storeName }}</p>
          </template>
        </Column>
        <!-- 社群 -->
        <Column
          v-if="fieldFilter[3].selected"
          field=""
          header="社群"
          style="min-width: 100px; max-width: 100px"
        >
          <template #body="{ data }">
            <!-- line -->
            <p
              class="fw-bolder text-break mb-2"
              v-if="data.userLineName"
            >
              <img
                class="icon"
                title="顧客的 Line 名稱"
                src="@/assets/icon/line-icon.png"
                alt=""
              />&ensp;{{ data.userLineName }}
            </p>
            <!-- fb -->
            <p
              class="fw-bolder text-break"
              v-if="data.userFbName"
            >
              <img
                class="icon"
                src="@/assets/icon/facebook-2.jpg"
                title="顧客的 Facebook 名稱" alt=""
              />&ensp;{{ data.userFbName }}
            </p>
          </template>
        </Column>
        <!-- 帳單 -->
        <Column
          v-if="fieldFilter[4].selected"
          class="fw-bolder"
          field="id"
          header="帳單"
          style="min-width: 200px; max-width: 200px"
          sortable
          :sortOrder="-1"
        >
          <template #body="{ data }">
            <p class="mb-2">
              <p class="badge bg-warning" v-if="data.hierarchy > 0">併單</p>
            </p>
            <p>單號: {{ data.id }}</p>
            <p>付款: {{ data.paymentType }}</p>
            <p
              class="text-danger can-click pointer mb-2 overflow-hidden"
              @click="showModal('editNote', data)"
              style="
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              ">備註: {{ data.note }}</p>
            <p title="帳單建立時間" style="cursor: help;">
              <i class="bi bi-calendar2-check me-1"></i>
              {{ $methods.moment(data.createTime).format('YYYY-MM-DD HH:mm:ss') }}
            </p>
          </template>
        </Column>
        <!-- 出貨 -->
        <Column
          v-if="fieldFilter[5].selected"
          class="fw-bolder"
          field=""
          header="出貨"
          style="min-width: 250px; max-width: 250px"
        >
          <template #body="{ data }">
            <p>出貨: {{ data.shippingType }}</p>
            <p>收件: {{ data.recipient }}</p>
            <p>電話: {{ data.recipientPhoneNumber }}</p>
            <p>
              地址:
              <span v-if="data.recipientPostalCode">({{ data.recipientPostalCode }})</span>
              <span v-if="data.recipientCity">{{ data.recipientCity }}</span>
              <span v-if="data.recipientArea">{{ data.recipientArea }}</span>
              <span v-if="data.receivingAddress">{{
                data.receivingAddress
              }}</span>
            </p>
          </template>
        </Column>
        <!-- 發票 -->
        <Column
          v-if="fieldFilter[6].selected"
          class="fw-bolder"
          field=""
          header="發票"
          style="min-width: 250px; max-width: 250px"
        >
          <template #body="{ data }">
            <p>抬頭: {{ data.receiptTitle }}</p>
            <p>統編: {{ data.receiptBusinessAdminNumber }}</p>
            <p>電話: {{ data.receiptPhoneNumber }}</p>
            <p>
              地址:
              <span v-if="data.receiptPostalCode">({{ data.receiptPostalCode }})</span>
              <span v-if="data.receiptCity">{{ data.receiptCity }}</span>
              <span v-if="data.receiptArea">{{ data.receiptArea }}</span>
              <span v-if="data.receiptAddress">{{
                data.receiptAddress
              }}</span>
            </p>
          </template>
        </Column>
        <!-- 付款 -->
        <Column
          v-if="fieldFilter[7].selected"
          class="fw-bolder"
          field="hasPaymentNotification"
          header="付款"
          style="min-width: 200px; max-width: 200px"
          sortable
        >
          <template #body="{ data }">
            <p>
              已通知付款:
              <span class="text-success" v-if="data.hasPaymentNotification">
                是
              </span>
              <span class="text-danger" v-else>否</span>
            </p>
            <div class="text-primary" v-if="data.hasPaymentNotification">
              <p>付款方式: 匯款</p>
              <p>付款金額: {{ data.paymentNotification.money }}</p>
              <p>
                銀行代碼:
                {{ data.paymentNotification.userPaymentInfo.bankCode }}
              </p>
              <p>
                帳號末五碼:
                {{ data.paymentNotification.userPaymentInfo.accountNumber }}
              </p>
              <p>付款備註: {{ data.paymentNotification.note }}</p>
              <p title="付款時間" style="cursor: help;">
                {{
                  $methods
                    .moment(data.paymentNotification.paymentTime)
                    .format("YYYY-MM-DD HH:mm:ss")
                }}
              </p>
            </div>
          </template>
        </Column>
        <!-- 總計 -->
        <Column
          v-if="fieldFilter[8].selected"
          class="fw-bolder"
          field=""
          header="總計"
          style="min-width: 190px"
        >
          <template #body="{ data }">
            <p>運費: {{ data.shippingCost }}</p>
            <p v-if="data.balanceTotal === 0">帳單平衡: {{ $methods.numberToFixed(data.balanceTotal) }}</p>
            <p class="pointer can-click" @click="showModal('showBalanceDetail', data)" v-else>
              帳單平衡: {{ $methods.numberToFixed(data.balanceTotal) }}
            </p>
            <p>
              訂單金額:
              <span class="text-danger" v-if="loadingForBodies">計算中...</span>
              <span v-else>{{ $methods.numberToFixed(data.bodiesTotal) }}</span>
            </p>
            <p class="badge bg-danger" v-if="data.discountName">
              {{ data.discountName }} ↓
            </p>
            <p>結單優惠: {{ data.discountAmount ? data.discountAmount : 0 }}</p>
            <p>購物金折抵: {{ data.walletUseAmount }}<span
                class="text-success"
                v-if="data.walletUsed && data.walletUseAmount > 0"
                >(已折)</span
              >
              <span
                class="text-danger"
                v-else-if="!data.walletUsed && data.walletUseAmount > 0"
                >(未折)</span
              ></p>
            <p class="mt-2 badge bg-info bg-gradient" v-if="data.showDetail">
              代客直寄
            </p>
            <hr />
            <p class="shouldBePay tw-text-size18 text-danger">
              應付金額:
              <span class="test-danger" v-if="loadingForBodies">計算中...</span>
              <span v-else>{{ data.shouldBePay }}</span>
            </p>
          </template>
        </Column>
        <!-- 操作 -->
        <Column v-if="fieldFilter[9].selected" field="shippedTime" header="操作" style="min-width: 300px" sortable>
          <template #body="{ data }">
            <div class="operate">
              <!-- 折抵 -->
              <span>
                <button
                  class="tw-btn tw-btn-success me-2 mb-2"
                  :class="{ 'not-allowed': data.walletUseAmount === 0 }"
                  :disabled="data.walletUseAmount === 0"
                  @click="showModal('walletUsed', data, 'single')"
                  v-if="data.walletUsed"
                >
                  已折抵
                </button>
                <button
                  class="tw-btn tw-btn-danger me-2 mb-2"
                  :class="{ 'not-allowed': data.walletUseAmount === 0 }"
                  :disabled="data.walletUseAmount === 0"
                  @click="showModal('walletUsed', data, 'single')"
                  v-else
                >
                  未折抵
                </button>
              </span>
              <!-- 收款 -->
              <span>
                <button
                  class="tw-btn tw-btn-success me-2 mb-2"
                  @click="showModal('paid', data, 'single')"
                  v-if="data.paid"
                >
                  已收款
                </button>
                <button
                  class="tw-btn tw-btn-danger me-2 mb-2"
                  @click="showModal('paid', data, 'single')"
                  v-else
                >
                  未收款
                </button>
              </span>
              <!-- 包貨 -->
              <span>
                <button
                  class="tw-btn tw-btn-success me-2 mb-2"
                  @click="showModal('packaged', data, 'single')"
                  v-if="data.packaged"
                >
                  已包貨
                </button>
                <button
                  class="tw-btn tw-btn-danger me-2 mb-2"
                  @click="showModal('packaged', data, 'single')"
                  v-else
                >
                  未包貨
                </button>
              </span>
              <!-- 寄出 -->
              <span>
                <button
                  class="tw-btn tw-btn-success me-2 mb-2"
                  @click="showModal('shipped', data, 'single')"
                  v-if="data.shipped"
                >
                  已寄出
                </button>
                <button
                  class="tw-btn tw-btn-danger me-2 mb-2"
                  @click="showModal('shipped', data, 'single')"
                  v-else
                >
                  未寄出
                </button>
              </span>
              <!-- 操作 -->
              <div class="dropdown d-inline-block me-1 mb-1">
                <button
                  class="tw-btn tw-btn-secondary tw-btn-hover dropdown-toggle"
                  id="MerchOrderAction"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  操作
                </button>
                <ul class="dropdown-menu" aria-labelledby="MerchOrderAction">
                  <li>
                    <button class="dropdown-item" disabled 
                      v-if="data.walletUsed || data.paid || data.packaged || data.shipped"
                    >
                      編輯(已折抵或已收款或已包貨或已寄出)
                    </button>
                    <router-link
                      v-else
                      :to="`/seller/store/${storeId}/editBill/${data.id}?participantId=${data.buyerId}`"
                      class="dropdown-item"
                    >編輯</router-link>
                  </li>
                  <li>
                    <button class="dropdown-item" disabled 
                      v-if="data.walletUsed || data.paid || data.packaged || data.shipped || data.hierarchy > 0"
                    >
                      拆單(已折抵或已收款或已包貨或已寄出或已併單)
                    </button>
                    <router-link
                      v-else
                      :to="`/seller/store/${storeId}/splitBill/${data.id}?participantId=${data.buyerId}`"
                      class="dropdown-item"
                    >拆單</router-link>
                  </li>
                  <li>
                    <button class="dropdown-item" disabled 
                      v-if="data.walletUsed || data.paid || data.packaged || data.shipped || data.hierarchy > 0"
                    >
                      刪除(已折抵或已收款或已包貨或已寄出或已併單)
                    </button>
                    <button
                      v-else
                      class="dropdown-item"
                      @click="showModal('del', data)"
                    >
                      刪除
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <p class="text-success fw-bold my-2" v-if="data.shipped">已寄出: {{ data.shippedTime }}</p>
          </template>
        </Column>
        <!-- 單身 -->
        <template #expansion="slotProps">
          <p class="fw-bolder text-danger" v-if="loadingForBodies">
            請稍後~正在為您取得帳單明細!
          </p>
          <div class="row" :class="`body${slotProps.data.id}`" v-else>
            <!-- 單身 -->
            <ul class="col-9" :class="{'col-12': slotProps.data.billBalances.length === 0}">
              <li
                class="row"
                v-for="buyerAndStore in slotProps.data.billBodiesMap"
                :key="buyerAndStore[0]"
              >
                <p class="col-12 fw-bolder mb-2">
                  {{ buyerAndStore[1].buyer }}@{{ buyerAndStore[1].storeName }}
                </p>
                <!-- 商品 -->
                <div class="border-bottom row pb-2 mb-2" v-for="merchandise in buyerAndStore[1].merchandises" :key="merchandise[0]">
                  <div class="col-2">
                    <div class="row">
                      <!-- 商品 -->
                      <div class="col-12 d-flex align-items-center">
                        <Image
                          class="me-2"
                          :imageHash="merchandise[1].merchandiseImgHash"
                          :lazy="false"
                          v-if="merchandise[1].merchandiseImgHash"
                          :alt="merchandise[1].merchandiseImgHash"
                          style="width: 50px; height: 50px"
                        ></Image>
                        <img
                          class="me-2"
                          src="@/assets/other-images/noImg.png"
                          alt=""
                          v-else
                          style="width: 50px; height: 50px"
                        />
                        <span>
                          <router-link
                            :title="`商品備註: ${merchandise[1].note}`"
                            :to="`/seller/store/${storeId}/merchandiseInfo/detail?merchandiseId=${merchandise[1].merchandiseId}`"
                            class="fw-bolder text-break pointer"
                            :class="{ 'text-danger': merchandise[1].deleted }"
                            target="_blank"
                            >{{ merchandise[1].merchandiseName }}</router-link
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- 訂單資訊 -->
                  <div class="col-10">
                    <div class="row mb-2" v-for="merchorder in merchandise[1].merchorders" :key="merchorder.id">
                    <div class="col-3">
                      <p>
                        訂單編號:
                        <router-link
                          :to="`/seller/store/${merchorder.merchOrder.storeId}/merchOrder?search=${merchorder.merchOrderId}`"
                          class="fw-bolder text-break pointer"
                          target="_blank"
                          >{{ merchorder.merchOrderId }}</router-link
                        >
                      </p>
                      <p v-if="merchorder.merchOrder.note">訂單備註: {{ merchorder.merchOrder.note }}</p>
                    </div>
                    <!-- 購買明細 -->
                    <div class="col-4">
                      <p>款式: {{ merchorder.style }}</p>
                    </div>
                    <!-- 狀態 -->
                    <div class="col-4">
                      <p class="fw-bolder" :class="merchorder.textColorForArrialStatus">
                        {{ merchorder.quantity }}X{{ merchorder.price }}={{
                          $methods.numberToFixed(merchorder.quantity * merchorder.price)
                        }}，到貨狀況: {{ merchorder.arrivalStatus }}
                      </p>
                    </div>
                  </div>
                  </div>
                </div>
              </li>
            </ul>
            <!-- 帳單平衡 -->
            <div class="col-3 alert-secondary py-2" v-if="slotProps.data.billBalances.length > 0">
              <ul>
                <li class="row fw-bolder text-danger">
                  <div class="col-8">
                    原因
                  </div>
                  <div class="col-4">
                    平衡價格
                  </div>
                </li>
                <hr>
                <li class="row fw-bolder" v-for="balance in slotProps.data.billBalances" :key="balance.id">
                  <div class="col-8">
                    {{ balance.description }}
                  </div>
                  <div class="col-4">
                    {{ balance.money }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </template>
        <template #empty>
          <p class="text-center fw-bolder text-primary">
            目前尚未有任何資料 0..0
          </p>
        </template>
      </DataTable>
    </div>
  </div>
  <!-- 併單的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="mergeModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">併單
            <a href="https://youtu.be/WqLmb5YKpfo?si=39kfU9QEgZTKUKvJ" target="_blank">
              <img
                class="info-icon"
                src="@/assets/icon/interrogation.png"
              />
            </a>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <p class="alert alert-warning mb-3">
            注意! 此併單功能一次限制最多 3
            筆帳單合併，故多餘的帳單系統將會自動選擇您所選到的前三筆帳單
          </p>
          <!-- 選擇預合併帳單 -->
          <div class="form-floating mb-3">
            <select
              class="form-select"
              id="MergeSelect"
              aria-label="請選擇預合併對象"
              v-model="selectMergeHeader"
            >
              <option value="null" selected disabled>請選擇預合併對象</option>
              <option :value="data" v-for="data in selectItems" :key="data.id">
                單號: {{ data.id }}，買家: {{ data.buyerName }}，賣場:
                {{ data.storeName }}
              </option>
            </select>
            <label for="MergeSelect">請選擇預合併對象</label>
          </div>
          <!-- 錯誤警告 -->
          <p class="alert alert-danger" v-if="errorData.length > 0">
            帳單單號:
            <span v-for="data in errorData" :key="data.id">
              {{ data.id }}&ensp;
            </span>
            已折抵或已收款或已包貨或已寄出 ! 故系統會自動幫您移除
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="goMergeBill"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 變更帳單狀態 (折抵，收款，包貨，出貨) -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="updateHeaderStatusModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">變更帳單狀態</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p class="fw-bolder">
            確定要變更
            <span class="text-primary" v-if="mode === 'walletUsed'">折抵</span>
            <span class="text-primary" v-else-if="mode === 'paid'">收款</span>
            <span class="text-primary" v-else-if="mode === 'packaged'">包貨</span>
            <span class="text-primary" v-else-if="mode === 'shipped'">寄出</span>
            <span class="text-primary" v-else>所有</span>
            狀態 ?
          </p>
          <hr>
          <div class="form-check form-switch p-0">
            <input class="form-check-input m-0 me-2" type="checkbox" role="switch"
              id="Notify"
              v-model="notify"
            >
            <label class="form-check-label" for="Notify">Notify 通知</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="updateHeaderStatus"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 解除併單的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="unmergeModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">解除併單</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 錯誤警告 -->
          <p class="alert alert-danger mb-3" v-if="errorData.length > 0">
            帳單單號:
            <span v-for="data in errorData" :key="data.id">
              {{ data.id }}&ensp;
            </span>
            因尚未併單 ! 故系統會自動幫您移除
          </p>
          <p>
            是否將帳單編號: 
            <span v-for="data in selectItems" :key="data.id">
              {{ data.id }}&ensp;
            </span>
            做解除併單呢 ?
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="unmerge">確認</button>
        </div>
      </div>
    </div>
  </div>
  <!-- 匯出的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="exportExcelModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">匯出</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p>
            確定將這些帳單匯出
            <span v-if="exportExcelMode === 'exportExcel'">Excel</span>
            <span v-else-if="exportExcelMode === 'exportFamilyExcel'"
              >好賣 +</span
            >
            <span v-else-if="exportExcelMode === 'exportSevenExcel'"
              >賣貨便</span
            >
            <span v-else-if="exportExcelMode === 'exportHilifeExcel'"
              >萊賣貨市集</span
            >
            嗎?
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="exportExcel"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 刪除 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="delModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-danger">
          <h5
            class="
              modal-title
              border-start
              ps-2
              border-5 border-danger
              text-danger
              fw-bolder
            "
          >
            刪除
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p>是否將以上帳單刪除呢 ?</p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="delHeader">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 撿貨總表的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="inspectionModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">撿貨總表</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p class="alert alert-warning">
            提醒 ! 勾選的筆數越多，開啟網頁的時間就會越慢喔 ~
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="goInspection">確認</button>
        </div>
      </div>
    </div>
  </div>
  <!-- 撿貨出貨單的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="shipperModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">撿貨出貨單</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          <p class="alert alert-warning">
            提醒 ! 勾選的筆數越多，開啟網頁的時間就會越慢喔 ~
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="goShipper">確認</button>
        </div>
      </div>
    </div>
  </div>
  <!-- 帳單平衡資訊的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="balanceDetailModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">帳單平衡資訊</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul v-if="selectItems.length > 0">
            <li class="row fw-bolder">
              <div class="col-7">
                原因
              </div>
              <div class="col-5">
                平衡價格
              </div>
            </li>
            <hr>
            <li class="row" v-for="data in selectItems[0].billBalances" :key="data.id">
              <div class="col-7">
                {{ data.description }}
              </div>
              <div class="col-5">
                {{ data.money }}
              </div>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            了解
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 表格可點選區介紹 modal -->
  <div
    class="modal fade"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="introductionModal"
  >
    <div class="modal-dialog" :style="scrollWidth">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">表格可點選區域</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div>
            <img
              src="@/assets/images/table-introduction/bill.jpg"
              style="max-width: 100%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 時間區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_TimeSwitch"
                v-model="advancedSearchData.timeRange.switch"
              />
              <label class="form-check-label" for="AS_TimeSwitch"
                >時間區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.timeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_StartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.timeRange.startTime"
                  />
                  <label for="AS_StartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_EndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.timeRange.endTime"
                  />
                  <label for="AS_EndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 寄出時間區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_ShippedTimeRange"
                v-model="advancedSearchData.shippedTimeRange.switch"
              />
              <label class="form-check-label" for="AS_ShippedTimeRange"
                >寄出時間區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.shippedTimeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_ShippedTimeRangeStartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.shippedTimeRange.startTime"
                  />
                  <label for="AS_ShippedTimeRangeStartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_ShippedTimeRangeEndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.shippedTimeRange.endTime"
                  />
                  <label for="AS_ShippedTimeRangeEndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 賣場 -->
          <div class="form-floating mb-3" v-if="!ownerStores.loading">
            <select
              class="form-select"
              id="AS_Store"
              v-model="advancedSearchData.store"
            >
              <option :value="null">不選擇</option>
              <option
                :value="data"
                v-for="data in ownerStores.data"
                :key="data.storeId"
              >
                {{ data.store.name }}
              </option>
            </select>
            <label for="AS_Store">賣場名稱</label>
          </div>
          <!-- 篩選條件 -->
          <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <li
                class="row border-bottom"
                v-for="data in singleSelectsArray"
                :key="data.id"
              >
                <div class="col-3 my-2">{{ data.key }}</div>
                <div class="col-9">
                  <div
                    class="select-radio my-2"
                    v-for="select in data.value"
                    :key="select.id"
                  >
                    <label>
                      <input
                        type="radio"
                        :name="data.id"
                        :value="select.value"
                        v-model="advancedSearchData.singleSelect[data.id]"
                      />
                      <span class="radio-style">{{ select.value }}</span>
                    </label>
                  </div>
                  <div class="form-floating mt-3" v-if="data.id === 'searchByContent'">
                    <input type="text" class="form-control" :id="`SearchText${data.id}`" maxlength="100" placeholder="輸入內容" v-model="advancedSearchData.searchContentText">
                    <label :for="`SearchText${data.id}`">輸入內容(100字)</label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 截圖結果的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="shotModal"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">截圖結果</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 產生截圖結果用的過程標籤 -->
          <div class="create-shot-result-area d-inline-block d-none">
            <img id="Img1" src="" alt="">
            <img id="Img2" src="" alt="">
          </div>
          <hr>
          <!-- 截圖結果 -->
          <img :src="shotUrl" alt="" style="max-width: 100%">
          <hr>
          <!-- 檔名 -->
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="ShotFileName" placeholder="檔名(20字)(非必填)" v-model="shotFileName" maxlength="20">
            <label for="ShotFileName">檔名(20字)(非必填)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="save">下載</button>
        </div>
      </div>
    </div>
  </div>
  <!-- 編輯備註的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editNoteModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">
            編輯備註
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="form-floating">
            <textarea
              class="form-control"
              placeholder="帳單備註"
              id="BillNote"
              style="height: 150px"
              v-model="note"
            >
            </textarea>
            <label for="BillNote">帳單備註</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="updateNote">確認</button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import { FilterMatchMode } from 'primevue/api';
// shot
import html2canvas from "html2canvas";
// vuex
import { mapState } from 'vuex'
// api
import { parseToken, sortoutParseTokenResult } from '../../methods/API/parseToken'
// components
import FieldFilter from '../../components/tools/FieldFilter.vue'

export default {
  props: {
    showWhitchModal: {
      type: Object,
      default: {
        merge: false,
        paid: false,
        packaged: false,
        shipped: false,
        allHeaderStatus: false,
        unmerge: false,
        exportExcel: false,
        exportFamilyExcel: false,
        exportSevenExcel: false,
        exportHilifeExcel: false,
        inspection: false,
        shipper: false,
        advancedSearch: false,
      }
    },
    frequentlySearchedStatus: {
      type: String,
      default: null
    },
  },
  emits: ['advancedSearchData'],
  components: {DataTable, Column, ColumnGroup, Row, FieldFilter},
  data() {
    return {
      // modal
      mergeModal: {},
      updateHeaderStatusModal: {},
      unmergeModal: {},
      exportExcelModal: {},
      delModal: {},
      inspectionModal: {},
      shipperModal: {},
      balanceDetailModal: {},
      introductionModal: {},
      advancedSearchModal: {},
      shotModal: {},
      editNoteModal: {},
      // data
      serverToken: '',
      storeId: 0,
      currentPage: 'Bill',
      // dataTable
      scrollWidth: 0,
      scrollHeight: 0,
      dataTabelLoading: false,
      merchOrderList: [],
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      // 選擇到的物件
      selectItems: [],
      alertItems: [],
      expandedRows: [],
      // modal 內呈現選擇到的物件資訊
      propsSelectInfos: {
        show: 'id',
        data: []
      },
      storeIds: [],
      billList: [],
      // 匯出 excel 的模式
      exportExcelMode: 'exportExcel',
      // 折抵按鈕按下時，須更新應付金額，所以 bodies 要做 loading 去計算應付
      loadingForBodies: false,
      // 變更帳單狀態
      updateHeaderStatusData: [],
      mode: 'all', // walletUsed, paid, packaged, shipped, allHeaderStatus
      notify: false,
      single: false,
      // 多選時有特別做篩選時，錯誤的資料放置區
      errorData: [],
      // 併單
      selectMergeHeader: null,
      // 截圖結果
      shotUrl: '',
      shotFileName: '',
      // 編輯備註
      note: '',
      // 進階搜尋
      advancedSearchData: {
        timeRange: {
          switch: true,
          startTime: null,
          endTime: null
        },
        shippedTimeRange: {
          switch: false,
          startTime: null,
          endTime: null
        },
        store: null,
        searchContentText: '',
        singleSelect: {
          billStatus: '未完成帳單',
          shippedStatus: '不選擇',
          paidStatus: '不選擇',
          mergeStatus: '不選擇',
          buyerNotifyStatus: '不選擇',
          arrivalStatus: '不選擇',
          searchByContent: '不選擇',
        }
      },
      recordAdvancedSearchData: {},
      updateStatus: 'first', // 判斷是否要進階搜尋，'first':頁面第一次載入，需跑進階搜尋 ； 'renew':特定幾筆資料更新，不跑進階搜尋。
      // 單選篩選陣列
      singleSelectsArray: [],
    }
  },
  created() {
    this.initialization()
    this.getPrefer()
  },
  mounted() {
    this.createModals(['mergeModal', 'updateHeaderStatusModal', 'unmergeModal', 'exportExcelModal', 'delModal', 'inspectionModal',
    'shipperModal', 'balanceDetailModal','introductionModal', 'advancedSearchModal', 'shotModal',
    'editNoteModal'])
    this.changeTableHeigth()
    // 監聽視窗異動
    window.addEventListener("resize", this.changeTableHeigth)
  },
  unmounted() {
    // 移除監聽視窗異動
    window.removeEventListener("resize", this.changeTableHeigth)
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-bill-local')
  },
  watch: {
    storeIds: {
      handler(val) {
        // 如果抓到所有賣場就可以開始取帳單資料
        console.log(val)
        if (val.length > 0) this.advancedSearch()
      },
      deep: true
    },
    showWhitchModal: {
      handler(val) {
        for (const [key, value] of Object.entries(val)) {
          console.log(key, value)
          if (value) return this.showModal(key)
        }
      },
      deep: true
    },
    // 父層 > 常用搜尋
    frequentlySearchedStatus(val) {
      // 判斷是否有未處理到的項目，提醒工程師用
      if (!val) return alert('沒有此搜尋選項')
      this.frequentlySearched(val)
    },
    recordAdvancedSearchData: {
      handler(val) {
        this.$emit('advancedSearchData', val)
      },
      deep: true
    },
  },
  computed: {
    ...mapState('StoreList', {
      ownerStores: state => state.ownerStores,
      customerStores: state => state.customerStores
    }),
    // 偏好 > 表格預設顯示筆數
    D4Row() {
      let D4Row = 20
        this.$store.state.Prefer.p_bill.rowCount.forEach(item => {
          if (item.selected) D4Row = item.value
        })
      return parseInt(D4Row)
    },
    // 偏好 > 進階搜尋預設時間區間
    timeRange() {
      return parseInt(this.$store.state.Prefer.p_bill.searchTimeRange[0].value)
    },
    // 偏好 > 表格欄位
    fieldFilter() {
      return this.$store.state.Prefer.p_bill.fieldFilter
    },
  },
  methods: {
    // 替每個 tr 上專屬的 class 名稱，好讓我可以截圖
    rowClass(data) {
      // console.log(data)
      return `header${data.id}`
    },
    // 展開全部
    expandAll() {
      this.expandedRows = this.billList;
    },
    // 收合全部
    collapseAll() {
      this.expandedRows = []
    },
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      this.currentPage = this.$route.name
      // 介紹表格可點選區寬度
      this.scrollWidth = `max-width: ${window.innerWidth - 20}px`
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-bill-local')
      // 紀錄進階搜尋單選條件
      this.singleSelectsArray = [
        {
          id: 'billStatus',
          key: '帳單狀態',
          value: [
            {
              id: 'A1',
              value: '未完成帳單'
            },
            {
              id: 'A2',
              value: '已完成帳單'
            },
            {
              id: 'A3',
              value: '所有帳單'
            },
          ]
        },
        {
          id: 'shippedStatus',
          key: '寄出狀態',
          value: [
            {
              id: 'B1',
              value: '不選擇'
            },
            {
              id: 'B2',
              value: '已寄出'
            },
            {
              id: 'B3',
              value: '未寄出'
            }
          ]
        },
        {
          id: 'paidStatus',
          key: '收款狀態',
          value: [
            {
              id: 'C1',
              value: '不選擇'
            },
            {
              id: 'C2',
              value: '已收款'
            },
            {
              id: 'C3',
              value: '未收款'
            }
          ]
        },
        {
          id: 'mergeStatus',
          key: '併單狀態',
          value: [
            {
              id: 'D1',
              value: '不選擇'
            },
            {
              id: 'D2',
              value: '併單'
            },
            {
              id: 'D3',
              value: '被併單'
            }
          ]
        },
        {
          id: 'buyerNotifyStatus',
          key: '顧客通知收款',
          value: [
            {
              id: 'E1',
              value: '不選擇'
            },
            {
              id: 'E2',
              value: '未通知'
            },
            {
              id: 'E3',
              value: '已通知'
            },
            {
              id: 'E3',
              value: '已通知付款但未確認'
            },
          ]
        },
        {
          id: 'arrivalStatus',
          key: '到貨狀態',
          value: [
            {
              id: 'F1',
              value: '不選擇'
            },
            {
              id: 'F2',
              value: '未到貨'
            },
            {
              id: 'F3',
              value: '全到貨'
            },
          ]
        },
        {
          id: 'searchByContent',
          key: '依內容搜尋',
          value: [
            {
              id: 'G1',
              value: '不選擇'
            },
            {
              id: 'G2',
              value: '依出貨或帳單備註搜尋'
            },
            {
              id: 'G3',
              value: '依帳單顧客資料搜尋'
            },
            {
              id: 'G3',
              value: '依帳單商品內容搜尋'
            },
            {
              id: 'G4',
              value: '依帳單編號搜尋'
            },
          ]
        },
      ]
      // 多紀錄進階搜尋
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    // dataTable 內容高度計算
    changeTableHeigth() {
      switch (this.currentPage) {
        case 'Bill':
          // 紀錄 dataTabel 高度 (40px: 最上面 marginTop, 170px: 表格中除了內容其餘多的)
          this.scrollHeight = window.innerHeight - document.getElementById('BillTopArea').clientHeight - 200
          break;
        case 'P_Bill':
          this.scrollHeight = window.innerHeight - 210
          break;
        default:
          throw new Error('BillDataTable.vue > changeTableHeigth > currentPage 未處理')
      }
    },
    // 開啟 modal
    showModal(status, item, mode) {
      if (status === 'merge') {
        // 併單
        this.selectMergeHeader = null
        this.showWhitchModal.merge = false
        if (this.selectItems.length < 2) return this.SweetAlert('other', '請至少選擇兩筆帳單')
        this.errorData = []
        let successData = []
        this.selectItems.forEach(item => {
          if (item.walletUsed || item.paid || item.shipped || item.packaged) this.errorData.push(item)
          else successData.push(item)
        })
        this.selectItems = successData.filter((item, index) => {
          if (index < 3) return item
        })
        this.mergeModal.show()
      } else if (status === 'allHeaderStatus' || status === 'walletUsed' || status === 'paid' || status === 'packaged' || status === 'shipped') {
        // 變更帳單狀態 (折抵，付款，包貨，出貨)
        if (mode === 'single') {
          this.getSelectInfo(item)
          this.single = true
        } else {
          this.propsSelectInfos.data = []
          this.propsSelectInfos.data = this.selectItems
        }
        this.updateHeaderStatusData = []
        this.mode = status // mode = walletUsed, paid, packaged, shipped, allHeaderStatus
        this.selectItems.forEach(item => {
          if (mode === 'single') {
            // 單個
            this.updateHeaderStatusData.push({
              id: item.id,
              walletUsed: {
                status: !item.walletUsed,
                notify: true,
              },
              paid: {
                status: !item.paid,
                notify: true,
              },
              packaged: {
                status: !item.packaged,
                notify: true,
              },
              shipped: {
                status: !item.shipped,
                notify: true,
              },
            })
          } else {
            // 一鍵
            this.updateHeaderStatusData.push({
              id: item.id,
              walletUsed: {
                status: true,
                notify: true,
              },
              paid: {
                status: true,
                notify: true,
              },
              packaged: {
                status: true,
                notify: true,
              },
              shipped: {
                status: true,
                notify: true,
              },
            })
            this.single = false
          }
        })
        if (this.updateHeaderStatusData.length === 0) return this.SweetAlert('other', '請至少選擇一筆帳單')
        this.updateHeaderStatusModal.show()
        this.showWhitchModal[status] = false
      } else if (status === 'unmerge') {
        // 解除併單
        this.showWhitchModal.unmerge = false
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆已併單的帳單')
        this.errorData = []
        let successData = []
        this.selectItems.forEach(item => {
          if (item.hierarchy === 0) this.errorData.push(item)
          else successData.push(item)
        })
        this.selectItems = successData
        if (this.selectItems.length === 0) return this.SweetAlert('other', '您所選擇到帳單皆尚未併單')
        this.unmergeModal.show()
      } else if (status === 'exportExcel') {
        // 匯出 excel
        this.showWhitchModal.exportExcel = false
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆帳單')
        this.exportExcelMode = status
        this.propsSelectInfos.data = []
        this.propsSelectInfos.data = this.selectItems
        this.exportExcelModal.show()
      } else if (status === 'exportFamilyExcel') {
        // 匯出好賣 +
        this.showWhitchModal.exportFamilyExcel = false
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆帳單')
        this.exportExcelMode = status
        this.propsSelectInfos.data = []
        this.propsSelectInfos.data = this.selectItems
        this.exportExcelModal.show()
      } else if (status === 'exportSevenExcel') {
        // 匯出賣貨便
        this.showWhitchModal.exportSevenExcel = false
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆帳單')
        this.exportExcelMode = status
        this.propsSelectInfos.data = []
        this.propsSelectInfos.data = this.selectItems
        this.exportExcelModal.show()
      } else if (status === 'exportHilifeExcel') {
        // 匯出萊賣貨
        this.showWhitchModal.exportHilifeExcel = false
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆帳單')
        this.exportExcelMode = status
        this.propsSelectInfos.data = []
        this.propsSelectInfos.data = this.selectItems
        this.exportExcelModal.show()
      } else if (status === 'del') {
        // 刪除帳單
        this.getSelectInfo(item)
        this.delModal.show()
      } else if (status === 'inspection') {
        // 撿貨總表
        this.showWhitchModal.inspection = false
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆帳單')
        this.propsSelectInfos.data = []
        this.propsSelectInfos.data = this.selectItems
        this.inspectionModal.show()
      } else if (status === 'shipper') {
        // 撿貨出貨單
        this.showWhitchModal.shipper = false
        if (this.selectItems.length === 0) return this.SweetAlert('other', '請至少選擇一筆帳單')
        this.propsSelectInfos.data = []
        this.propsSelectInfos.data = this.selectItems
        this.shipperModal.show()
      } else if (status === 'showBalanceDetail') {
        // 顯示帳單平衡資訊
        this.selectItems = []
        this.selectItems.push(item)
        this.balanceDetailModal.show()
      } else if (status === 'advancedSearch') {
        // 進階搜尋
        this.showWhitchModal.advancedSearch = false
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      } else if (status === 'editNote') {
        // 編輯備註
        this.selectItems = []
        this.selectItems.push(item)
        this.note = item.note
        this.editNoteModal.show()
      }
    },
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
      // 先取得預設時間區間
      this.computedD4TimeRange()
      this.getUserInfoForStoreList()
    },
    // 取得使用者資訊
    getUserInfoForStoreList() {
      this.$methods.switchLoading('show')
      this.storeIds = []
      const result = parseToken(this.serverToken)
      result.then(res => {
        const result = sortoutParseTokenResult(res)
        this.getPhysicalUserInfoForStoreList(result)
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert('other', err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 取得實體使用者賣場列表
    getPhysicalUserInfoForStoreList(userInfo) {
      const vm = this
      const getPhysicalUserInfo = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 1,
          ids: userInfo.ids,
          methods: '{getParticipants{getParticipantPermission{isInPeriod},getStore}}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getPhysicalUserInfo,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const physicalUserInfo = res.data[0].objects[0]
            physicalUserInfo.participants.forEach(item => {
              if ((item.authority === 'OWNER' || (item.authority === 'CUSTOMER' && item.participantPermission && item.participantPermission.inPeriod)) && !item.store.deleted) vm.storeIds.push(item.storeId)
            })
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert('other', err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        }
      })
    },
    // * 更新資料 (start)
    /**
     * 更新資料
     * @updateItem 要更新的物件
     * @modal 需要關閉的 modal
     */
    updateData(updateItem, modal) {
      this.$methods.switchLoading('show')
      let data = []
      let ids = []
      this.selectItems.forEach(bill => { ids.push(bill.id) })
      switch (updateItem) {
        case 'updateBillHeader':
          data = [{
            ids: ids,
            type: 27,
            methods: '{getStore,getBuyer{getUser{getUserLineLink,getUserFacebookLink}},getPaymentNotification{getUserPaymentInfo{getPhysicalUser},isConfirmed},getReceiptPostalCodeDetail,getBillBalances,getRecipientPostalCodeDetail}'
          }]
          break;
        case 'unmerge':
          // todo 等ming回來，請他幫我們在解除併單完，回傳解除併單後的 billHeader ID
          data = [{
            ids: ids,
            type: 27,
            methods: '{getStore,getBuyer{getUser{getUserLineLink,getUserFacebookLink}},getPaymentNotification{getUserPaymentInfo{getPhysicalUser},isConfirmed},getReceiptPostalCodeDetail,getBillBalances,getRecipientPostalCodeDetail}'
          }]
          break;
        default:
          throw new Error('BillDataTable.vue > updateData > updateItem 未處理')
      }
      // 取要更新的資料
      const updateData = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      };
      const vm = this
      $.ajax({
        type: "POST",
        async: true,
        url: updateData,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: function (res) {
          console.log(res);
          if (res.code === '200') {
            const bills = res.data[0].objects
            vm.sortoutUpdateData(bills, updateItem, modal)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      });
    },
    // 整理更新資料
    sortoutUpdateData(bills, updateItem, modal) {
      this.updateStatus = 'renew' // 指定更新狀態為'renew'，使單身資料更新時不會跑進階搜尋
      const updateIds = bills.map(obj => obj.id)
      switch (updateItem) {
        case 'updateBillHeader': 
          this.billList.some((origin, originIndex) => {
            bills.some((update, updateIndex) => {
              if(origin.id === update.id) {
                const updateObj = this.handleBills(update)
                
                // 導回單身至更新的物件
                updateObj.billBodies = origin.billBodies
                updateObj.bodiesTotal = origin.bodiesTotal
                updateObj.billBodiesMap = origin.billBodiesMap

                // 計算應付金額
                const shippingCost = updateObj.shippingCost ? updateObj.shippingCost : 0
                const discountAmount = updateObj.discountAmount ? updateObj.discountAmount : 0
                let money =  updateObj.walletUseAmount ? updateObj.walletUseAmount : 0
                if (!updateObj.walletUsed) money = 0
                updateObj.shouldBePay = 0
                updateObj.shouldBePay = this.$methods.numberToFixed(shippingCost + updateObj.balanceTotal + updateObj.bodiesTotal - money - discountAmount)

                // 取代原本物件
                this.billList[originIndex] = updateObj

                bills.splice(updateIndex, 1)
                return true
              }
            })
            if (bills.length === 0) return true
          })
          break;
        case 'deleteBill':
          this.billList = this.billList.filter(origin => {
            return !updateIds.includes(origin.id)
          })
          break;
        default:
          throw new Error('BillDataTable.vue > sortoutUpdateData > updateItem 未處理')
      }
      // this.SweetAlert('200')
      if (modal) {
        this[modal].hide()
      }
      this.selectItems = []
      this.$methods.switchLoading('hide')
    },
    // * 更新資料 (end)
    // * 取帳單 (start)
    // 計算預設時間區間
    computedD4TimeRange() {
      this.advancedSearchData.timeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.timeRange.startTime = this.$methods.moment().subtract(this.timeRange, 'days').format('YYYY-MM-DD')
    },
    // 常用搜尋
    frequentlySearched(searchStatus) {
      // 紀錄要變更的單選項目
      let singleSelectOption = null
      // 還原所有單選選項預設值
      this.advancedSearchData.singleSelect.mergeStatus = '不選擇'
      this.advancedSearchData.singleSelect.buyerNotifyStatus = '不選擇'
      this.advancedSearchData.singleSelect.arrivalStatus = '不選擇'
      // 判斷是哪個單選項目要變更
      if (searchStatus === '併單') singleSelectOption = 'mergeStatus'
      else if (searchStatus === '已通知付款但未確認') singleSelectOption = 'buyerNotifyStatus'
      else if (searchStatus === '未到貨' || searchStatus === '全到貨') singleSelectOption = 'arrivalStatus'
      // 判斷是否有未處理到的項目，提醒工程師用
      if (!singleSelectOption) return alert('沒有此搜尋選項')
      this.advancedSearchData.singleSelect[singleSelectOption] = searchStatus
      this.advancedSearch()
    },
    // 進階搜尋
    advancedSearch(closeModal) {
      this.updateStatus = 'first' // 指定為'first' 代表要跑後續單身的進階搜尋
      this.dataTabelLoading = true
      this.$methods.switchLoading('show')
      if (this.$route.query.search === 'trace') {
        // 追查帳單
        this.getBillBodiesByTrace()
      } else {
        // 一般狀態
        this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
        const query = {"columns":[{"columnName":"id","siteObjectType":27}],"group":{"groups":[],"operator":1},"siteObjectType":27,"sqlCommandType":1}
        // 判斷是顧客還是賣場的帳單
        if (this.$route.name === 'P_Bill') {
          // 如果是顧客 > 我的帳單
          query.group.groups.push({
            column: {
              columnName: "buyerId",
              siteObjectType: 27
            },
            operator: {
              operator: "=",
              type: 1,
              value: this.$route.params.participantId
            }
          })
        } else {
          // 對帳出貨
          // 進階搜尋 > 賣場名稱
          if (this.advancedSearchData.store) {
            query.group.groups.push({
              column: {
                columnName: "storeId",
                siteObjectType: 27
              },
              operator: {
                operator: "=",
                type: 1,
                value: this.advancedSearchData.store.storeId
              }
            })
          } else {
            query.group.groups.push({
              column: {
                columnName: "storeId",
                siteObjectType: 27
              },
              operator: {
                type: 3,
                value: this.storeIds
              }
            })
          }
        }
        console.log(new Date().getTimezoneOffset()*60*100)
        // 時間區間
        if (this.advancedSearchData.timeRange.switch) {
          if (this.advancedSearchData.timeRange.startTime) {
            const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.startTime} 00:00:00`) / 1000
            query.group.groups.push({
              column: {
                columnName: 'createTime',
                siteObjectType: 27
              },
              operator: {
                operator: ">=",
                type: 1,
                value: {
                  name: `from_unixtime(${startTime})`
                }
              }
            })
          }
          if (this.advancedSearchData.timeRange.endTime) {
            const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.endTime} 23:59:59`) / 1000
            query.group.groups.push({
              column: {
                columnName: 'createTime',
                siteObjectType: 27
              },
              operator: {
                operator: "<=",
                type: 1,
                value: {
                  name: `from_unixtime(${endTime})`
                }
              }
            })
          }
        }
        // 寄出時間
        if (this.advancedSearchData.shippedTimeRange.switch) {
          if (this.advancedSearchData.shippedTimeRange.startTime) {
            const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.shippedTimeRange.startTime} 00:00:00`) / 1000
            query.group.groups.push({
              column: {
                columnName: 'shippedTime',
                siteObjectType: 27
              },
              operator: {
                operator: ">=",
                type: 1,
                value: {
                  name: `from_unixtime(${startTime})`
                }
              }
            })
          }
          if (this.advancedSearchData.shippedTimeRange.endTime) {
            const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.shippedTimeRange.endTime} 23:59:59`) / 1000
            query.group.groups.push({
              column: {
                columnName: 'shippedTime',
                siteObjectType: 27
              },
              operator: {
                operator: "<=",
                type: 1,
                value: {
                  name: `from_unixtime(${endTime})`
                }
              }
            })
          }
        }
        // 帳單狀態
        switch (this.advancedSearchData.singleSelect.billStatus) {
          case '未完成帳單':
            query.group.groups.push({
              groups: [{
                column: {
                  columnName: "paid",
                  siteObjectType: 27
                },
                operator: {
                  operator: "=",
                  type: 1,
                  value: false
                }
              },
              {
                column: {
                  columnName: "packaged",
                  siteObjectType: 27
                },
                operator: {
                  operator: "=",
                  type: 1,
                  value: false
                }
              },
              {
                column: {
                  columnName: "shipped",
                  siteObjectType: 27
                },
                operator: {
                  operator: "=",
                  type: 1,
                  value: false
                }
              }],
              operator: 2
            })
            break;
          case '已完成帳單':
            query.group.groups.push({
              groups: [{
                column: {
                  columnName: "paid",
                  siteObjectType: 27
                },
                operator: {
                  operator: "=",
                  type: 1,
                  value: true
                }
              },
              {
                column: {
                  columnName: "packaged",
                  siteObjectType: 27
                },
                operator: {
                  operator: "=",
                  type: 1,
                  value: true
                }
              },
              {
                column: {
                  columnName: "shipped",
                  siteObjectType: 27
                },
                operator: {
                  operator: "=",
                  type: 1,
                  value: true
                }
              }],
              operator: 1
            })
            break;
        }
        // 寄出狀態
        switch (this.advancedSearchData.singleSelect.shippedStatus) {
          case '已寄出':
            query.group.groups.push({
              column: {
                columnName: "shipped",
                siteObjectType: 27
              },
              operator: {
                operator: "=",
                type: 1,
                value: true
              }
            })
            break;
          case '未寄出':
            query.group.groups.push({
              column: {
                columnName: "shipped",
                siteObjectType: 27
              },
              operator: {
                operator: "=",
                type: 1,
                value: false
              }
            })
            break;
        }
        // 收款狀態
        switch (this.advancedSearchData.singleSelect.paidStatus) {
          case '已收款':
            query.group.groups.push({
              column: {
                columnName: "paid",
                siteObjectType: 27
              },
              operator: {
                operator: "=",
                type: 1,
                value: true
              }
            })
            break;
          case '未收款':
            query.group.groups.push({
              column: {
                columnName: "paid",
                siteObjectType: 27
              },
              operator: {
                operator: "=",
                type: 1,
                value: false
              }
            })
            break;
        }
        // 併單狀態
        switch (this.advancedSearchData.singleSelect.mergeStatus) {
          case '併單':
            query.group.groups.push({
              column: {
                columnName: "parentId",
                siteObjectType: 27,
              },
              operator: {
                operator: "true", // 這組 operator 是指 parentId = null
                type: 4,
              },
            })
            query.group.groups.push({
              column: {
                columnName: "hierarchy",
                siteObjectType: 27,
              },
              operator: {
                operator: ">",
                type: 1,
                value: 0,
              },
            })
            break;
          case '被併單':
            query.group.groups.push({
              column: {
                columnName: "parentId",
                siteObjectType: 27,
              },
              operator: {
                operator: "false", // 這組 operator 是指 parentId = null
                type: 4,
              },
            })
            query.group.groups.push({
              column: {
                columnName: "hierarchy",
                siteObjectType: 27,
              },
              operator: {
                operator: "=",
                type: 1,
                value: 0,
              },
            })
            break;
          case '不選擇':
            query.group.groups.push({
              column: {
                columnName: 'parentId',
                siteObjectType: 27
              },
              operator: {
                operator: "true",
                type: 4,
              }
            })
            break;
        }
        if (closeModal) this.advancedSearchModal.hide()
        this.getBillList(query)
      }
    },
    // 取得帳單
    getBillList(query) {
      const vm = this
      const getBillListApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          query: query,
          methods: '{getStore,getBuyer{getUser{getUserLineLink,getUserFacebookLink}},getPaymentNotification{getUserPaymentInfo{getPhysicalUser},isConfirmed},getReceiptPostalCodeDetail,getBillBalances,getRecipientPostalCodeDetail}' 
        }
      ]
      this.billList = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getBillListApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const billList = res.data[0].objects
            vm.sortoutBills(billList)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 整理帳單
    sortoutBills(billList) {
      let headerIds = []
      billList.forEach(item => {
        headerIds.push(item.id)
        // 將處理帳單物件提成function，以供單一資料更新時不跑以下進階搜尋的程式
        const object = this.handleBills(item)

        // 進階搜尋 > 顧客通知收款
        let matchBuyerNotifyStatus = false
        switch (this.advancedSearchData.singleSelect.buyerNotifyStatus) {
          case '未通知':
            if (!item.paymentNotification.createTime) matchBuyerNotifyStatus = true
            break;
          case '已通知':
            if (item.paymentNotification.createTime) matchBuyerNotifyStatus = true
            break;
          case '已通知付款但未確認':
            if (item.paymentNotification.createTime && !item.paid) matchBuyerNotifyStatus = true
            break;
          case '不選擇':
            matchBuyerNotifyStatus = true
            break;
        }

        // 進階搜尋 > 依內容搜尋
        let matchSearchByContent = false
        let combineString = ''
        switch (this.advancedSearchData.singleSelect.searchByContent) {
          case '依出貨或帳單備註搜尋':
            if (item.shippingType) combineString += item.shippingType
            if (item.recipient) combineString += item.recipient
            if (item.recipientPhoneNumber) combineString += item.recipientPhoneNumber
            if (object.recipientCity) combineString += object.recipientCity
            if (object.recipientArea) combineString += object.recipientArea
            if (object.receivingAddress) combineString += object.receivingAddress
            if (object.note) combineString += object.note
            if (combineString.match(this.advancedSearchData.searchContentText)) matchSearchByContent = true
            break;
          case '依帳單顧客資料搜尋':
            combineString += `${object.buyerId}${object.buyerName}`
            if (item.buyer.nickName) combineString += item.buyer.nickName
            if (object.userLineName) combineString += object.userLineName
            if (object.userFbName) combineString += object.userFbName
            if (combineString.match(this.advancedSearchData.searchContentText)) matchSearchByContent = true
            break;
          case '依帳單編號搜尋':
            console.log(item.id, this.advancedSearchData.searchContentText, item.id == this.advancedSearchData.searchContentText)
            if (item.id == this.advancedSearchData.searchContentText) matchSearchByContent = true
            break;
          case '依帳單商品內容搜尋':
            matchSearchByContent = true
            break;
          case '不選擇':
            matchSearchByContent = true
            break;
        }

        if (matchBuyerNotifyStatus && matchSearchByContent) this.billList.push(JSON.parse(JSON.stringify(object)))
      })
      this.$methods.switchLoading('hide')
      if (this.advancedSearchData.singleSelect.searchByContent === '依帳單商品內容搜尋' || this.advancedSearchData.singleSelect.arrivalStatus !== '不選擇') this.dataTabelLoading = true
      else this.dataTabelLoading = false
      this.getBillBodies(headerIds)
    },
    // 特別處理帳單物件
    handleBills(item) {
      // 原資料
      const object = {
        billBalances: item.billBalances,
        balanceTotal: 0,
        billBodies: null,
        billBodiesMap: null,
        bodiesTotal: null,
        shouldBePay: null,
        buyer: item.buyer,
        profilePicture: null,
        buyerId: item.buyerId,
        buyerName: item.buyer.user.name,
        createTime: item.createTime,
        discountAmount: item.discountAmount,
        discountName: item.discountName,
        discountType: item.discountType,
        hierarchy: item.hierarchy,
        id: item.id,
        note: item.note,
        packaged: item.packaged,
        paid: item.paid,
        parentId: item.parentId,
        paymentNotification: item.paymentNotification,
        hasPaymentNotification: item.paymentNotification.paymentTime ? true : false,
        paymentType: item.paymentType,
        // 收據
        receiptAddress: item.receiptAddress,
        receiptBusinessAdminNumber: item.receiptBusinessAdminNumber,
        receiptPhoneNumber: item.receiptPhoneNumber,
        receiptPostalCode: item.receiptPostalCode,
        receiptCity: item.receiptPostalCode ? item.receiptPostalCodeDetail.City : '',
        receiptArea: item.receiptPostalCode ? item.receiptPostalCodeDetail.Area : null,
        receiptTitle: item.receiptTitle,
        // 收件資訊
        receivingAddress: item.receivingAddress,
        recipient: item.recipient,
        recipientPhoneNumber: item.recipientPhoneNumber,
        recipientPostalCode: item.recipientPostalCode,
        recipientCity: item.recipientPostalCode ? item.recipientPostalCodeDetail.City : '',
        recipientArea: item.recipientPostalCode ? item.recipientPostalCodeDetail.Area : null,
        shipped: item.shipped,
        shippedTime: this.$methods.moment(item.shippedTime).format('YYYY-MM-DD HH:mm:ss'),
        shippingCost: item.shippingCost,
        shippingType: item.shippingType,
        showDetail: item.showDetail,
        storeId: item.storeId,
        storeName: item.store.name,
        userLineName: item.buyer.user.userLineLink && item.buyer.user.userLineLink.appUserId ? item.buyer.user.userLineLink.name : null,
        userFbName: item.buyer.user.userFacebookLink && item.buyer.user.userFacebookLink.appUserId ? item.buyer.user.userFacebookLink.name : null,
        walletUseAmount: item.walletUseAmount,
        walletUsed: item.walletUsed
      }
      // 大頭照
      if (item.buyer.user.userLineLink && item.buyer.user.userLineLink.profilePicture) object.profilePicture = item.buyer.user.userLineLink.profilePicture
      else if (item.buyer.user.userFacebookLink && item.buyer.user.userFacebookLink.profilePicture) object.profilePicture = item.buyer.user.userFacebookLink.profilePicture
      // 帳單平衡總計
      object.balanceTotal = 0
      item.billBalances.forEach(item => {
        object.balanceTotal += item.money
      })
      return object
    },
    // 非同步取得帳單單身
    getBillBodies(headerIds) {
      const vm = this
      const getBillBodiesApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 27,
          ids: headerIds,
          methods: '{getBillBodies{getTopestBillHeader,getMerchOrder{getMerchandise{getMerchandisePictureLinks},getMerchandiseStyles,getBuyer{getUser},getStore}}}' 
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getBillBodiesApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const billList = res.data[0].objects
            vm.setBillBodies(billList)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 將單身塞回去單頭
    setBillBodies(billList) {
      this.loadingForBodies = true
      setTimeout(() => {
        this.billList.forEach(originBill => {
          billList.some(newBill => {
            if (originBill.id === newBill.id) {
              originBill.bodiesTotal = 0
              originBill.billBodiesMap = new Map()
              newBill.billBodies.forEach(body => {
                // 計算單身總價
                originBill.bodiesTotal += body.price * body.quantity
                // 取得商品圖片
                body.merchandiseImgHash = null
                body.merchOrder.merchandise.merchandisePictureLinks.some(img => {
                  if (img.front) return body.merchandiseImgHash = img.imageHash
                })
                // 取商品名稱
                body.merchandiseName = body.merchOrder.merchandise.name
                // 組商品款式
                body.style = ''
                body.merchOrder.merchandiseStyles.forEach(style => {
                  body.style += `${style.name} `
                })
                // 訂單來自哪個賣場
                body.storeName = body.merchOrder.store.name
                // 買家
                body.buyerName = body.merchOrder.buyer.user.name
                // 到貨狀況
                body.arrivalStatus = null
                body.textColorForArrialStatus = 'text-danger'
                if (body.merchOrder.allocatedQuantity === 0) {
                  body.arrivalStatus = '未到貨'
                  body.textColorForArrialStatus = 'text-secondary'
                } else if (body.merchOrder.quantity === body.merchOrder.allocatedQuantity) {
                  body.arrivalStatus = '全到貨'
                  body.textColorForArrialStatus = 'text-success'
                } else if (body.quantity === body.merchOrder.quantity && body.merchOrder.allocatedQuantity > 0) {
                  body.arrivalStatus = `到貨: ${body.merchOrder.allocatedQuantity}個`
                  body.textColorForArrialStatus = 'text-warning'
                } else {
                  body.arrivalStatus = '分批結單，無法判斷到貨數量'
                  body.textColorForArrialStatus = 'text-danger'
                }
                // * 整理畫面顯示
                // 先以顧客@賣場
                const buyerAndStore = `${body.merchOrder.buyerId}@${body.merchOrder.storeId}`
                if (!originBill.billBodiesMap.has(buyerAndStore)) {
                  originBill.billBodiesMap.set(buyerAndStore, {
                    buyer: body.buyerName,
                    storeName: body.storeName,
                    merchandises: new Map()
                  })
                }
                const merchandiseMap = originBill.billBodiesMap.get(buyerAndStore).merchandises
                // 在以同商品
                if (!merchandiseMap.has(body.merchOrder.merchandiseId)) {
                  merchandiseMap.set(body.merchOrder.merchandiseId, {
                    merchandiseName: body.merchandiseName,
                    merchandiseImgHash: body.merchandiseImgHash,
                    merchandiseId: body.merchOrder.merchandiseId,
                    note: body.merchOrder.merchandise.note,
                    deleted: body.merchOrder.merchandise.deleted,
                    merchorders: []
                  })
                }
                merchandiseMap.get(body.merchOrder.merchandiseId).merchorders.push(body)
              })
              console.log(originBill.billBodiesMap)
              // 計算應付金額
              const discountAmount = originBill.discountAmount ? originBill.discountAmount : 0 // (促銷優惠)
              const shippingCost = originBill.shippingCost ? originBill.shippingCost : 0
              let money =  originBill.walletUseAmount ? originBill.walletUseAmount : 0
              if (!originBill.walletUsed) money = 0
              originBill.shouldBePay = 0
              originBill.shouldBePay = this.$methods.numberToFixed(shippingCost + originBill.balanceTotal + originBill.bodiesTotal - money - discountAmount)
              originBill.billBodies = newBill.billBodies
              return true
            }
          })
        })
        // 若為第一次載入，就跑進階搜尋 (到貨狀態，依帳單商品內容搜尋)
        if (this.updateStatus === 'first') this.afterAdvancedSearch()
        this.dataTabelLoading = false
        this.loadingForBodies = false
      }, 1)
    },
    // 後續需要帳單與訂單資料的進階搜尋 (到貨狀態，依帳單商品內容搜尋)
    afterAdvancedSearch() {
      console.log('跑進階搜尋')
      // 進階搜尋 > 到貨狀態 (這兩個判斷皆不考慮 <分批結單，無法判斷到貨數量已通知但未確認>)
      switch (this.advancedSearchData.singleSelect.arrivalStatus) {
        case '未到貨':
          this.billList = this.billList.filter(item => {
            let matchNotInStock = item.billBodies.some(body => {
              return body.arrivalStatus === '未到貨'
            })
            return matchNotInStock
          })
          break;
        case '全到貨':
          this.billList = this.billList.filter(item => {
            let notMatchArrivalStatus = item.billBodies.some(body => {
              return body.arrivalStatus !== '全到貨' && body.arrivalStatus !== '分批結單，無法判斷到貨數量'
            })
            return !notMatchArrivalStatus
          })
          break;
      }
      // 進階搜尋 > 依帳單商品內容搜尋
      if (this.advancedSearchData.singleSelect.searchByContent === '依帳單商品內容搜尋') {
        this.billList = this.billList.filter(item => {
          return item.billBodies.some(body => {
            const combineString = `${body.merchandiseName}${body.style}`
            if (combineString.match(this.advancedSearchData.searchContentText)) return true
          })
        })
      }
    },
    // * 取帳單 (end)
    // * 追查帳單 start
    // 用訂單 ID 找最上階帳單
    getBillBodiesByTrace() {
      const vm = this
      const getBillBodiesByTraceApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 26,
          ids: [this.$route.query.merchOrderId],
          methods: '{getBillBodies{getTopestBillHeader}}' 
        }
      ]
      this.billList = []
      $.ajax({
        type: 'POST',
        async: true,
        url: getBillBodiesByTraceApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchOrderInfo = res.data[0].objects[0]
            let topestHeaderIds = []
            merchOrderInfo.billBodies.forEach(body => {
              topestHeaderIds.push(body.topestBillHeader.id)
            })
            vm.getBillHeadersByTrace(topestHeaderIds)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 取得最上階帳單 ID 陣列再取更詳細的帳單資訊
    getBillHeadersByTrace(topestHeaderIds) {
      const vm = this
      const getBillHeadersByTraceApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 27,
          ids: topestHeaderIds,
          methods: '{getStore,getBuyer{getUser{getUserLineLink,getUserFacebookLink}},getPaymentNotification{getUserPaymentInfo{getPhysicalUser},isConfirmed},getReceiptPostalCodeDetail,getBillBalances,getRecipientPostalCodeDetail}' 
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getBillHeadersByTraceApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const billList = res.data[0].objects
            vm.sortoutBills(billList)
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // * 追查帳單 end
    // 取得選取到的資訊
    getSelectInfo(item) {
      // 準備 modal 內選擇幾筆資訊
      this.selectItems = []
      this.propsSelectInfos.data = []
      this.selectItems.push(item)
      this.propsSelectInfos.data = this.selectItems
    },
    // 變更帳單狀態 (折抵，收款，包貨，寄出)
    updateHeaderStatus() {
      this.$methods.switchLoading('show')
      const vm = this
      const updateHeaderStatusApi = `${process.env.VUE_APP_API}/bill/updateHeaderStatus`
      const header = {
        authorization: this.serverToken
      }
      let data = []
      this.updateHeaderStatusData.forEach(item => {
        let obj = {}
        obj.id = item.id
        // mode = walletUsed, paid, packaged, shipped, allHeaderStatus
        if (this.mode === 'walletUsed') {
          item.walletUsed.notify = this.notify
          obj.walletUsed = item.walletUsed
        }
        if (this.mode === 'paid') {
          item.paid.notify = this.notify
          obj.paid = item.paid
        }
        if (this.mode === 'packaged') {
          item.packaged.notify = this.notify
          obj.packaged = item.packaged
        }
        if (this.mode === 'shipped') {
          item.shipped.notify = this.notify
          obj.shipped = item.shipped
        }
        if (this.mode === 'allHeaderStatus') {
          item.walletUsed.notify = false
          item.paid.notify = false
          item.packaged.notify = false
          item.shipped.notify = this.notify
          obj.walletUsed = item.walletUsed
          obj.paid = item.paid
          obj.packaged = item.packaged
          obj.shipped = item.shipped
        }
        data.push(obj)
      })
      console.log(data)
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateHeaderStatusApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          vm.handleUpdateHeaderStatus(res)
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 處理一件設定帳單完的結果
    handleUpdateHeaderStatus(res) {
      let message = ''
      if (res.code !== '200') {
        // 處理有錯誤的帳單
        res.errorData.forEach(item => {
          message += `${item.message}: `
          item.errorData.forEach((bill, index, arr) => {
            message += `${bill.id}`
            if (index !== arr.length - 1) message += '，'
          })
          message += '<br/>'
        })
        // 處理完成的帳單
        if (res.data.length > 0) {
          message += '完成帳單: '
          res.data.forEach((bill, index, arr) => {
            message += `${bill.id}`
            if (index !== arr.length - 1) message += '，'
          })
        }  
      }
      this.SweetAlert(res.code, message)
      this.updateData('updateBillHeader', 'updateHeaderStatusModal')
    },
    // 若單一變更為折抵狀態，則另外計算價格部分
    updateShouldBePay(headerId) {
      const vm = this
      this.loadingForBodies = true
      setTimeout(() => {
        vm.billList.some(item => {
          if (item.id === headerId) {
            const discountAmount = item.discountAmount ? item.discountAmount : 0
            const shippingCost = item.shippingCost ? item.shippingCost : 0
            let money =  item.walletUseAmount ? item.walletUseAmount : 0
            if (!item.walletUsed) money = 0
            item.shouldBePay = this.$methods.numberToFixed(shippingCost + item.balanceTotal + item.bodiesTotal - discountAmount - money)
            // 如果折抵完應付金額剛好等於 0 那就將付款狀態變已付款
            if (item.shouldBePay === 0) item.paid = true
            return true
          }
        })
        vm.loadingForBodies = false
      }, 1)
    },
    // 截圖 (內包含兩張截圖並組圖)
    shot(billHeader) {
      this.$methods.switchLoading('show')
      const headerId = billHeader.id;
      this.shotModal.show()
      // 清空截圖結果
      this.shotUrl = ''
      this.shotFileName = ''
      // 隱藏不要須被截圖的地方
      $('.operate').addClass('d-none')
      $('.shot-icon').addClass('d-none')
      // 去除有預設字體大小的 class
      $('.shouldBePay').removeClass('tw-text-size18')
      // 這個區塊的標籤是用來生成截圖用的
      $('.create-shot-result-area').removeClass('d-none')
      // 打開所有單身
      this.expandedRows = this.billList.filter(item => item.id === billHeader.id);
      // 將字體放大到 24 px
      $('.p-datatable-tbody').addClass('tw-text-size24')
      // 這裡必須使用實體的 img 節點，若用 js (new Image) 產生 img 標籤則取不到他的寬高
      const vm = this
      setTimeout(() => {
        let img1 = document.getElementById('Img1')
        let img2 = document.getElementById('Img2')
        img1.src = ''
        img2.src = ''
        // 先截單頭
        html2canvas(document.querySelector(`.header${headerId}`), {
          backgroundColor: "#fff",
          useCORS: true,
          windowWidth: "1600px",
          scale: 1,
        }).then((canvas) => {
          // 截圖結果記錄在 img1
          img1.src = canvas.toDataURL("image/png")
          // 再截單身 (截圖為非同步，所以我得確保先截單頭再截單身這個步驟)
          html2canvas(document.querySelector(`.body${headerId}`), {
            backgroundColor: "#fff",
            useCORS: true,
            windowWidth: "1600px",
            scale: 1,
          }).then((canvas) => {
            // 將結果紀錄再 img2
            img2.src = canvas.toDataURL("image/png")
            // 將兩張圖結合在 div 內在截一次圖
            html2canvas(document.querySelector('.create-shot-result-area'), {
              backgroundColor: "#fff",
              useCORS: true,
              windowWidth: "1600px",
              scale: 1,
            }).then((canvas) => {
              // 將結果紀錄再 img2
              vm.shotUrl = canvas.toDataURL("image/png")
              // 復原畫面
              $('.p-datatable-tbody').removeClass('tw-text-size24')
              $('.operate').removeClass('d-none')
              $('.shot-icon').removeClass('d-none')
              $('.create-shot-result-area').addClass('d-none')
              $('.shouldBePay').addClass('tw-text-size18')
              vm.$methods.switchLoading('hide')
            })
          })
        })
      }, 2000)
    },
    // 儲存截圖
    save() {
      const now = this.$methods.moment(new Date()).format('YYYYMMDD-HHmmss')
      let alink = document.createElement("a")
      const shotFileName = this.shotFileName.replace(/\s+/g, '')
      alink.href = this.shotUrl
      alink.download = shotFileName ? shotFileName : now
      alink.click()
      this.shotModal.hide()
    },
    // 匯出各種 excel
    exportExcel() {
      const token = this.serverToken;
      let exportExcelArr = []
      this.selectItems.forEach((item) => {
        exportExcelArr.push(item.id)
      })
      const ids = exportExcelArr.toString()
      let typeStr = "";
      let idsStr = "";
      let apiStr = "";
      switch (this.exportExcelMode) {
        case "exportExcel":
          apiStr = "exportDataTableExcel";
          typeStr = "siteObjectTypeIndex=27";
          idsStr = `ids=${ids}`;
          break;
        case "exportFamilyExcel":
          apiStr = "exportShipmentExcel";
          typeStr = "type=family";
          idsStr = `billHeaderIds=${ids}`;
          break;
        case "exportSevenExcel":
          apiStr = "exportShipmentExcel";
          typeStr = "type=seven";
          idsStr = `billHeaderIds=${ids}`;
          break;
        case "exportHilifeExcel":
          apiStr = "exportShipmentExcel";
          typeStr = "type=hilife";
          idsStr = `billHeaderIds=${ids}`;
          break;
      }
      const exportExportUrl = `${process.env.VUE_APP_API}/download/${apiStr}?authorization=${token}&${typeStr}&${idsStr}`;
      window.open(exportExportUrl, "_blank");
      this.selectItems = []
      this.exportExcelModal.hide()
    },
    // 前往併單
    goMergeBill() {
      if (!this.selectMergeHeader) return this.SweetAlert('other', '您尚未選擇預合併對象')
      if (this.selectItems.length < 2) return this.SweetAlert('other', '您的帳單筆數不足 2 筆')
      const parentHeader = this.selectMergeHeader
      let childHeaders = []
      this.selectItems.forEach(item => {
        if (item.id !== parentHeader.id) childHeaders.push(item.id)
      })
      this.mergeModal.hide()
      this.$router.push(`/seller/store/${this.storeId}/mergeBill?participantId=${parentHeader.buyerId}&parent=${parentHeader.id}&child=${childHeaders}`)
    },
    // 解除併單
    unmerge() {
      const vm = this
      const unmergeApi = `${process.env.VUE_APP_API}/bill/unmergeHeaders`
      const header = {
        authorization: this.serverToken
      }
      let headerIds = []
      this.selectItems.forEach(item => {
        headerIds.push(item.id)
      })
      const data = new FormData()
      data.append('headerIds', headerIds.toString())
      $.ajax({
        type: 'DELETE',
        async: true,
        url: unmergeApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.unmergeModal.hide()
            vm.advancedSearch()
            vm.selectItems = []
            // todo 待 ming 回傳解併 billsHeader ID 後，更改資料更新方式
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 刪除
    delHeader() {
      const vm = this
      const delHeaderApi = `${process.env.VUE_APP_API}/bill/deleteHeader`
      const header = {
        authorization: this.serverToken
      }
      let headerIds = []
      this.selectItems.forEach(item => {
        headerIds.push(item.id)
      })
      const data = new FormData()
      data.append('headerIds', headerIds.toString())
      $.ajax({
        type: 'DELETE',
        async: true,
        url: delHeaderApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.sortoutUpdateData(vm.selectItems, 'deleteBill', 'delModal')
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 前往撿貨總表
    goInspection() {
      let headerIds = []
      this.selectItems.forEach(item => {
        headerIds.push(item.id)
      })
      let href = `/seller/store/${this.storeId}/inspection?headerIds=${headerIds}`
      window.open(href, "_blank");
      this.selectItems = []
      this.inspectionModal.hide()
    },
    // 前往撿貨出貨單
    goShipper() {
      let headerIds = []
      this.selectItems.forEach(item => {
        headerIds.push(item.id)
      })
      let href = `/seller/store/${this.storeId}/Shipper?headerIds=${headerIds}`
      window.open(href, "_blank");
      this.selectItems = []
      this.shipperModal.hide()
    },
    // 編輯備註
    updateNote() {
      this.$methods.switchLoading('show')
      const vm = this
      const updateBillApi = `${process.env.VUE_APP_API}/bill/updateHeader`
      const header = {
        authorization: this.serverToken
      }
      const d4Bill = this.selectItems[0]
      const data = [{
        id: d4Bill.id,
        note: this.note,
      }]
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateBillApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.$methods.switchLoading('hide')
            vm.billList.some(item => {
              if (item.id === d4Bill.id) {
                item.note = vm.note
                return true
              }
            })
            vm.editNoteModal.hide()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
  },
}
</script>